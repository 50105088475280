<template>
  <div class="custom-page-container">
    <div class="custom-page-title-module">{{ pageInfo.title }}</div>
    <div
      class="custom-page-content-module"
      v-html="pageInfo.content"
    ></div>
  </div>
</template>
<script>
import { getCustomPageDetails } from '@/api'

export default {
  data() {
    return {
      pageInfo: {
        title: '',
        content: '',
      },
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      const { id } = this.$route.params
      this.requestGetCustomPageDetails({ pageIds: [id] })
    },
    async requestGetCustomPageDetails(params) {
      const response = await getCustomPageDetails(params)
      const { code, data } = response
      if (code === 200) {
        this.pageInfo = data[0] || {}
      }
    },
  },
}
</script>
<style lang="less" scoped>
.custom-page-container {
  width: 70%;
  margin: 0 auto;
  .custom-page-title-module {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }
  .custom-page-content-module {
    padding: 32px 0;
  }
}
</style>
